<template>
  <v-container fluid class="pt-0">
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="1" class="fill-height" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список груп документа
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <MainModal
                  :main="{ component: 'DocumentGroupType', title: 'Група документа' }"
                  :button="{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' }"
                  :item="{parent_id: null}"
                  @updateItemModal="updateItemModal"
              />
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-treeview
                dense
                hoverable
                activatable
                :items="items"
                :open="open"
                :search="search"
                :filter="filter"
                :active.sync="selection"
                return-object
                color="grey darken-3"
                style="cursor: pointer !important;"
                class="group-elements"
            >
              <template v-slot:prepend="{ item }">
                <MainModal
                      :main="{ component: 'DocumentGroupType', title: 'Група документа' }"
                      :button="{ iconActivator: true, class: 'mr-2', color: 'secondary', buttonIcon: 'mdi-folder', iconSize: 24 }"
                      :item="{
                          id: item.id,
                          name: item.name,
                          parent_id: item.parent_id
                        }"
                      :modal="dialog_id === item.id"
                      @updateItemModal="updateItemModal"
                  />
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import organizationDocumentTypeAPI from "@/utils/axios/organization_douments"
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  props: ['selectable', 'model'],
  name: "DocumentGroupView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    filter () {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
  },
  data() {
    return {
      items: [],
      selected: [],
      selection: [],
      open: [],
      search: null,
      tableHeader: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Найменування', value: 'name' },
        { text: 'Створено', value: 'create_date' },
        { text: 'Автор', value: 'owner_name' },
      ],
      dialog_id: 0
    }
  },
  methods: {
    fetch() {
      organizationDocumentTypeAPI.get_group_type_tree()
        .then(response => response.data)
        .then(data => {
          this.items = data
          this.open = []
          this.items.forEach((item, idx) => {
            if ((item.children || []).length) {
              this.open.push(idx)
            }
          })
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    openComponentModal(payload) {
      this.dialog_id = payload.id
    },
    updateItemModal() {
      this.dialog_id = 0
    }
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    },
    selection(value) {
      if (value.length) {
        const payload = {
          id: value[0].id,
          name: value[0].name,
          parent_id: value[0].parent_id,
        }

        this.selectRow({item: payload, value: payload.id})
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>